.app {
  text-align: center;
}

.logo {
  height: auto;
  max-width: 400px;
  pointer-events: none;
}

.body {
  background-color: #000000;
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.link {
  color: #61dafb;
}